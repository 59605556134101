.root {
    height: 100%;
    background: black;
    position: relative;
    .tlTrigger{
        //position: absolute;
        height: 10000px;
        width: 100%;
        .tlTriggerScroll{
            height: 100%;
            width: 10px;
            position: absolute;
            left: 0;
            background-color: rgba(255,0,0,1);
            z-index: 1000;
        }
    }
    
    .video_background {
        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .phoneBg{
        position: fixed;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .text1{
        
        opacity: 1;
        white-space: nowrap;
        aspect-ratio: 2.8916;
        /*background-size: cover;
        background-repeat: no-repeat;
        background-position: center;*/
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            width: 30vw;
        }
    }

    .midContent{
        width: 100%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content:space-around;
        z-index: 9999;
        .left{
            display: flex;
            .img1,.img2{
                width: 260px;
                height: 601px;
                background-image: url(../../../public/img/UI_Picture_Assets_LiveStream.png);
                background-size: 100% auto;
            }
            .img1{
                margin-top: 35px;
                margin-right: 40px;
                background-image: url(../../../public/img/UI_Picture_Assets_Explore.png);
            }
        }
        .right{
            width: 50vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .appButton{
            width: 260px;
            aspect-ratio: 3.7957;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0,0,0,1);
            font-size: 30px;
            color: #333333;
            cursor: pointer;
        }
        .intro{
            margin-top: 10px;
            margin-bottom: 35px;
            font-size: 24px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            a{
                text-decoration: none;
                font-size: 32px;
                padding-bottom: 7px;
                border-bottom: 1px solid #fff;
            }
        }
    }
    .verticalText2{
        padding-top: 300px;
    }
    .horizontalText2{
        padding-top: 200px;
    }
    .text2{
        position: fixed;
        left: 53%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 100vw;
        min-height: 100vh;
        white-space: nowrap;
        padding-left: 40px;
        .text2Part1{
            text-align: left;
            visibility: hidden;
        }
        .text2Part2{
            text-align: left;
            visibility: hidden;
        }
        .text2Part3{
            text-align: left;
            visibility: hidden;
        }
        .text2Part4{
            text-align: left;
            visibility: hidden;
        }
    }
    .page2LongText1{
        visibility: hidden;
    }
    .page2LongText2{
        visibility: hidden;
    }
    .page3LongText1{
        visibility: hidden;
    }
    .page3LongText2{
        visibility: hidden;
    }
    .commonPageLongText{
        position: fixed;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -50%);
        max-width: 100vw;
        max-height: 100vh;
        width: 80%;
        text-align: left;
        .longTextPoint{
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: rgba(255,255,255,1);
            border-radius: 10px;
            left: -40px;
            top: 10px;
        }
    }
    .verticalPage2ImageRoot{
        height: 80vw;
        top: 60%;//30%
    }
    .horizontalPage2ImageRoot{
        height: 50vh;
        top: 40%;//15%
    }
    .page2ImageRoot{
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        visibility: hidden;
        .page2CommonImage{
            height: 100%;
            aspect-ratio: 1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 40px;
            margin: 0 30px;
        }
    }
    .page2ImageRoll{
        animation: roll 15s linear infinite;
    }
    .comingSoon{
        position: fixed;
        left: 50%;
        top: 50%;
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        translate: -50% -50%;
        white-space: nowrap;
        .comingSoonText1{
            visibility: hidden;
            scale: 0.5;
        }
        .comingSoonText2{
            visibility: hidden;
            scale: 0.5;
        }
    }
    .topBarRoot{
        width: 100%;
        height: 50px;
        position: fixed;
        //background-color: red;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        cursor: pointer;
        .socialIcon{
            margin-right: 20px;
        }
        
    }
    .turnupLogo{
        position: fixed;
        top: 6px;
        left: 10px;
    }
    .iconList{
        position: fixed;
        bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        overflow-x: auto;
        animation: roll 60s linear infinite;
        //visibility: hidden;
        .item{
            margin-right: 20px;
        }
    }
    @keyframes roll {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-50%);
        }
    }
    
}

@primary-color: #1DA57A;