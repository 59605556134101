body {
  font-family: "Protest Strike", fantasy, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  background: black;
	/*height:100%; */
  /*overflow: hidden;*/
  margin: 0;
}

html{
  -ms-overflow-style: none;
  /*scrollbar-width: none;*/
}

/* Hide scrollbar for Chrome, Safari and Opera */
body:-webkit-scrollbar {
  display: none;
}

/* prevent pull-to-refresh for Safari 16+ */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (overscroll-behavior-y: none)  {
    html {
      min-height: 100.3%;
      overscroll-behavior-y: none;
    }
  }
}
/* prevent pull-to-refresh for Safari 9~15 */
@media screen and (pointer: coarse) {
  @supports (-webkit-backdrop-filter: blur(1px)) and (not (overscroll-behavior-y: none))  {
    html {
      height: 100%;
      overflow: hidden;
    }
    body {
      margin: 0px;
      max-height: 100%; /* or `height: calc(100% - 16px);` if body has default margin */
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    /* in this case to disable pinch-zoom, set `touch-action: pan-x pan-y;` on `body` instead of `html` */
  }
}
/* prevent pull-to-refresh for Chrome 63+ */
body{
  overscroll-behavior-y: none;
}

*{
  color: white;
}
.collap{
  border: none;
  position: relative;
  
}
.collap.ant-collapse{
  border-top: 1px solid #595959;
  border-radius: 0;
}
.collap.ant-collapse::before{
  content: '';
  width: 60px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 0;
  background-color: #00FF01;
}

.collap.ant-collapse::after{
  content: '';
  width: 12px;
  height: 3px;
  position: absolute;
  top: -1px;
  left: 60px;
  background-color: black;
}
.collap.ant-collapse>.ant-collapse-item:last-child {
  border-radius: 0;
}
.collap.ant-collapse .ant-collapse-content{
  background-color: transparent;
}
.collap.ant-collapse .ant-collapse-content{
  border: none;
}
.collap.ant-collapse>.ant-collapse-item{
  position: relative;
  border-bottom:1px solid #595959;
}
.collap.ant-collapse>.ant-collapse-item::before{
  content: '';
  width: 60px;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: #00FF01;
}

.collap.ant-collapse>.ant-collapse-item::after{
  content: '';
  width: 12px;
  height: 3px;
  position: absolute;
  bottom: -1px;
  left: 60px;
  background-color: black;
}
.collap.ant-collapse>.ant-collapse-item >.ant-collapse-header{
  padding: 16px 0;
}
.collap.ant-collapse .ant-collapse-content>.ant-collapse-content-box{
  padding: 10px 0;
}
.collap.ant-collapse.ant-collapse-icon-position-end>.ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
  padding-block-start: 14px;
}

.verticalObj{
  max-width: calc(100vh * 9 / 21) !important; 
}

.mb22{
  margin-bottom: 22px;
}